import { dataMenuButtons, links } from "@/data/menu.data";
import { useMobxStores } from "@/store";
import PulseStore from "@/store/PulseStore";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { Conditional, ResponsiveLazyImage } from "..";
import styles from "./navigation.module.scss";
import { Context } from "@/context";
import Image from "../NextImage";
import { brand } from "@/assets";
import MenuLabelDesktop from "../MenuLabel/desktop";
import MenuLabelMobile from "../MenuLabel/mobile";
import MenuButtonsDesktop from "../MenuButtons/desktop";
import MenuButtonsMobile from "../MenuButtons/mobile";
import DataLayer from "@/utils/DataLayer";
import Logo from "../svg-components/Logo";

const Navigation: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  return (
    <>
      {state.layout.menuOpen && (
        <div
          className={styles.gestureDetector}
          onClick={() => {
            dispatch({
              type: "SET_MENU_OPEN",
              payload: { menuOpen: false },
            });
            DataLayer.closeEvent({
              elementCategory: 'imagem',
              element: 'menu',
              pageSection: 'header',
              pageSubsection: 'menu',
            });

          }}
        />
      )}
      <nav
        id="mainNav"
        className={scssStyles([
          styles.container,
          state.layout.menuOpen ? styles.open : "",
        ])}
      >
        <div className={styles.scroller}>
          <Conditional notOn="mobile">
            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <a target="no_blank" href={links.fiat}>
                  <Logo/>
                </a>
              </div>
            </div>
          </Conditional>
          <Conditional notOn="mobile">
            <div className={styles.menuLinks}>
              <MenuLabelDesktop />
            </div>
            {!!dataMenuButtons.length && (
              <div className={styles.buttonsHolder}>
                <MenuButtonsDesktop />
              </div>
            )}
          </Conditional>
          <Conditional notOn="desktop">
            <div className={styles.menuLinks}>
              <MenuLabelMobile />
            </div>
            {!!dataMenuButtons.length && (
              <div className={styles.buttonsHolder}>
                <MenuButtonsMobile />
              </div>
            )}
            <br />
          </Conditional>
        </div>
      </nav>
    </>
  );
});

export default Navigation;
