import scssStyles from '@/utils/scssStyles';
import { FC, MouseEventHandler } from 'react';
import { Chevron } from '../svg-components';
import styles from './ArrowButton.module.scss';

interface ArrowButtonProps {
  previous?: boolean;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  secondary?: boolean;
  light?: boolean;
  large?: boolean;
  disabled?: boolean;
  color?: string;
}

const ArrowButton: FC<ArrowButtonProps> = ({
  previous = false,
  className = '',
  secondary = false,
  disabled = false,
  light = false,
  large = false,
  color="#FDB72B",
  handleClick,
}) => {
  const title = previous ? 'Anterior' : 'Próximo';
  return (
    <button
      title={title}
      className={scssStyles([
        styles.button,
        className,
        secondary ? styles.secondary : '',
        light ? styles.light : '',
        large ? styles.large : '',
        disabled ? styles.disabled : '',
      ])}
      onClick={handleClick}
    >
      <Chevron color={color} thicker direction={previous ? 'left' : 'right'} />
    </button>
  );
};

export default ArrowButton;
