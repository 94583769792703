import CTAButton from "@/components/cta-button";
import { dataMenuButtonsDesktop } from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import React, { FC } from "react";
import styles from "../../navigation/navigation.module.scss";

const MenuButtonsDesktop: FC = () => {
  return (
    <>
      {dataMenuButtonsDesktop.map((item) => {
        const layerEvent = () => {
          DataLayer.clickEvent({
            elementCategory: "cta",
            element: item.label,
            pageSection: "header",
            pageSubsection: "menu",
          });
        };
        return (
          <CTAButton
            variant="terciary"
            key={item.id}
            href={item.url}
            className={styles.menuButton}
            text={item.label}
            title={item.label}
            handleAuxClick={() => layerEvent()}
            handleClick={() => layerEvent()}
            iconCode={item.iconCode}
          />
        );
      })}
    </>
  );
};

export default MenuButtonsDesktop;
