import CTAButton from '@/components/cta-button';
import { dataMenuButtons } from '@/data/menu.data';
import DataLayer from '@/utils/DataLayer';
import React, { FC } from 'react';
import styles from '../../navigation/navigation.module.scss';

const MenuButtonsMobile: FC = () => {
  return (
    <>
      {dataMenuButtons.map((item) => {
        const layerEvent = () => {
          DataLayer.clickEvent({
            elementCategory: 'cta',
            element: item.label,
            pageSection: 'header',
            pageSubsection: 'menu',
          });
        };
        return (
          <CTAButton
          footer
          style={{borderBottom: "none"}}
            key={item.id}
            href={item.url}
            className={styles.menuButton}
            text={item.label}
            title={item.label}
            handleAuxClick={() => layerEvent()}
            handleClick={() => layerEvent()}
            iconCode={item.iconCode}
          />
        );
      })}
    </>
  );
};

export default MenuButtonsMobile;
