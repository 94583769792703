import { Context } from '@/context';
import { dataMenuLabels } from '@/data/menu.data';
import scssStyles from '@/utils/scssStyles';
import React, { FC, useContext, useEffect } from 'react';
import styles from '../../navigation/navigation.module.scss';
import productDetails, { ProductTab } from "@/data/product-details.data";
import DataLayer from '@/utils/DataLayer';

const MenuLabelMobile: FC = () => {
  const { state, dispatch } = useContext(Context);
  const handleSelectedDetailTab = (
    tab: ProductTab | undefined | null
  ): void => {
    if (tab) {
      dispatch({ type: "SET_CURRENTLY_VISIBLE_NAV", payload: { tab: tab } });
    }

    dispatch({
      type: "SET_DETAIL_TAB",
      payload: {
        isMobile: true,
        selectedDetailTab: tab,
      },
    });
  };
  return (
    <>
      {dataMenuLabels().map((item, index) => {
        const active = item.reference
          ? item.reference.id === state.layout.currentlyVisibleNav ||
            item.id === state.layout.currentlyVisibleNav 
          : item.id === state.layout.currentlyVisibleNav ;
        const layerEvent = () => {
          DataLayer.clickEvent({
            elementCategory: 'link',
            element: item.label,
            pageSection: 'header',
            pageSubsection: 'menu',
          });

        };
        return (
          <a
            key={item.id}
            className={scssStyles([
              styles.menuLinks,
              active ? styles.active : '',
            ])}
            href={item.url}
            onAuxClick={() => layerEvent()}
            onClick={(e) => {
              if (item.reference) {
                DataLayer.clickEvent({
                  elementCategory: "link",
                  element: item.label,
                  pageSection: "header",
                  pageSubsection: "menu",
                });

                dispatch({
                  type: "SCROLL_TO_REFERENCE",
                  payload: { reference: item.reference },
                });
                const tabReference = productDetails.find(
                  (p) => p.slug === item.slug
                );
                if (tabReference) {
                  handleSelectedDetailTab(
                    state.layout.selectedDetailTab === tabReference.id
                      ? null
                      : tabReference
                  );
                }
                dispatch({
                  type: "SET_MENU_OPEN",
                  payload: { menuOpen: false },
                });
              } else {
                if (index === 0) {
                  DataLayer.clickEvent({
                    elementCategory: "link",
                    element: item.label,
                    pageSection: "header",
                    pageSubsection: "menu",
                  });
                  window?.scrollTo({ top: 0, behavior: "smooth" });
                } else {
                  DataLayer.clickEvent({
                    elementCategory: "link",
                    element: item.label,
                    pageSection: "header",
                    pageSubsection: "menu",
                  });
                  dispatch({
                    type: "SCROLL_TO_REFERENCE",
                    payload: { reference: item },
                  });
                }
                dispatch({
                  type: "SET_MENU_OPEN",
                  payload: { menuOpen: false },
                });
              }

              e.preventDefault();
            }}
          >
            {item.label}
          </a>
        );
      })}
    </>
  );
};

export default MenuLabelMobile;
