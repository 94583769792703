import {
  IncludePrefixResponsiveSizes,
  includeResponsive,
} from "@/utils/imagePrefixes";

const GALLERY_PREFIX = "Gallery/";

interface VideoProps {
  desktop: string;
  mobile: string;
}
export interface GalleryAsset {
  id: string;
  title: string;
  alt: string;
  asset: IncludePrefixResponsiveSizes;
  video?: VideoProps;
}

export const gallery: GalleryAsset[] = [
  // Thumbs Left
  {
    id: "ga01",
    title: "Argo Porta-Malas.",
    alt: "Porta-malas de Fiat Argo com malas e bolsa de viagem.",
    asset: includeResponsive(`${GALLERY_PREFIX}argo-trekking-farois`, "webp"),
  },
  {
    id: "ga03",
    title: "Volante.",
    alt: "Volante de Fiat Argo na cor preta com logo da Fiat em prata no meio.",
    asset: includeResponsive(`${GALLERY_PREFIX}argo-trekking-volante`, "webp"),
  },
  {
    id: "ga05",
    title: "Painel",
    alt: "Painel de Fiat Argo na cor preta com central multimídia de 7 polegadas e volante multifuncional.",
    asset: includeResponsive(`${GALLERY_PREFIX}argo-trekking-painel`, "webp"),
  },
  {
    id: "ga07",
    title: "Adesivo.",
    alt: "Detalhe com nome da versão no porta-malas de Fiat Argo Trekking de cor vermelha.",
    asset: includeResponsive(`${GALLERY_PREFIX}argo-trekking-adesivo`, "webp"),
  },

  // Thumbs Right
  {
    id: "ga02",
    title: "Porta malas.",
    alt: "Porta-malas de Fiat Argo com malas e bolsa de viagem.",
    asset: includeResponsive(
      `${GALLERY_PREFIX}argo-trekking-porta-malas`,
      "webp"
    ),
  },
  {
    id: "ga04",
    title: "Bancos.",
    alt: "Interior de Fiat Argo com bancos em couro e detalhe da costura em laranja.",
    asset: includeResponsive(`${GALLERY_PREFIX}argo-trekking-banco`, "webp"),
  },
  {
    id: "ga06",
    title: "Motor.",
    alt: "Motor Firefly de Fiat Argo.",
    asset: includeResponsive(`${GALLERY_PREFIX}argo-trekking-motor`, "webp"),
  },
  {
    id: "ga08",
    title: "Traseira",
    alt: "Traseira de Fiat Argo, um hatch, de cor vermelha.",
    asset: includeResponsive(`${GALLERY_PREFIX}argo-trekking-traseira`, "webp"),
  },
];

export const galleryThumbs = gallery.map((g) => ({
  ...g,
  thumb: `${g.asset.path}-thumbnail.${g.asset.extension}`,
}));
