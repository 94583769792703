import { MetaType } from "@models/index";

export const PRODUCT_NAME = "Template";
export const BRAND_NAME = "DigitalCode";
export const PAGE_TITLE = `${BRAND_NAME} ${PRODUCT_NAME}`;

export const metaTags: MetaType = {
  description:
    "Bem-vindo ao Fabuloso Design do Fiat Argo",
  title: "Fiat Argo",
  brandName: "Fiat",
  productName: "Argo",
  heading: "Projeto template",
  twitter: {
    card: "",
    site: "",
    image: "",
  },
  url: "",
  image: "",
  imageAlt: "",
};

export const STORAGE_PREFIX = "@digital_code/";

export const brandLinks = {
  mainUrl: "https://argo.fiat.com.br/",
  privacyPolicy: "https://www.fiat.com.br/politica-de-privacidade.html ",
  twitter: "https://twitter.com/fiatbr",
  facebook: "https://www.facebook.com/fiatbr",
  instagram: "https://www.instagram.com/fiatbr",
};

export * as menu from "../data/menu.data";
