import { FC } from "react";

const SocialIcons: FC = ({}) => {
  return (
    <svg
      id="Redes_Sociais"
      data-name="Redes Sociais"
      xmlns="http://www.w3.org/2000/svg"
      width="311"
      height="32.773"
      viewBox="0 0 311 32.773"
    >
      <g
        id="_001-facebook-app-logo"
        data-name="001-facebook-app-logo"
        transform="translate(0 0)"
      >
        <path
          id="Caminho_4"
          data-name="Caminho 4"
          d="M30.963,0H1.809A1.809,1.809,0,0,0,0,1.81V30.964a1.81,1.81,0,0,0,1.809,1.81H17.5V20.082H13.234V15.135H17.5V11.488c0-4.232,2.584-6.538,6.361-6.538a35.422,35.422,0,0,1,3.816.194V9.568H25.062c-2.053,0-2.449.976-2.449,2.408v3.157h4.9L26.87,20.08H22.612V32.772h8.351a1.811,1.811,0,0,0,1.81-1.808V1.809A1.809,1.809,0,0,0,30.963,0Z"
          transform="translate(0 -0.001)"
          fill="#272727"
        />
      </g>
      <g
        id="_002-youtube-logo"
        data-name="002-youtube-logo"
        transform="translate(91.632 0)"
      >
        <path
          id="YouTube"
          d="M30.6,23.972H28.8l.008-1.045a.848.848,0,0,1,.847-.844h.115a.849.849,0,0,1,.849.844Zm-6.746-2.239a.769.769,0,0,0-.83.682V27.5a.847.847,0,0,0,1.661,0V22.415A.77.77,0,0,0,23.855,21.733Zm10.956-2.842v9.666a4.36,4.36,0,0,1-4.468,4.216H11.968A4.36,4.36,0,0,1,7.5,28.557V18.891a4.36,4.36,0,0,1,4.468-4.216H30.343A4.36,4.36,0,0,1,34.811,18.891ZM13.194,29.607V19.425H15.47V17.917L9.4,17.908v1.483l1.9.005v10.21Zm6.828-8.665h-1.9v5.437a9.636,9.636,0,0,1,0,1.319c-.154.422-.848.87-1.119.046a10.821,10.821,0,0,1-.006-1.327l-.008-5.475H15.1L15.1,26.33c0,.826-.019,1.442.007,1.722.046.494.03,1.07.488,1.4.854.615,2.492-.092,2.9-.972l0,1.123,1.525,0V20.941ZM26.1,27.168l0-4.525c0-1.725-1.292-2.757-3.043-1.362l.008-3.364-1.9,0-.009,11.612,1.56-.023.142-.723C24.847,30.614,26.1,29.361,26.1,27.168Zm5.943-.6-1.424.008c0,.056,0,.122,0,.193v.795a.777.777,0,0,1-.778.772h-.279a.778.778,0,0,1-.779-.772v-2.09h3.261V24.246a19.361,19.361,0,0,0-.1-2.306c-.233-1.622-2.51-1.879-3.66-1.049a2.144,2.144,0,0,0-.8,1.072,6.038,6.038,0,0,0-.242,1.913v2.7C27.242,31.06,32.69,30.426,32.04,26.568Zm-7.3-14.649a1.293,1.293,0,0,0,.457.577,1.323,1.323,0,0,0,.777.216,1.209,1.209,0,0,0,.728-.226,1.745,1.745,0,0,0,.533-.681l-.036.745h2.117v-9H27.644v7.006a.694.694,0,0,1-1.388,0V3.547H24.517V9.618c0,.773.014,1.289.037,1.551A2.61,2.61,0,0,0,24.736,11.919ZM18.321,6.835a7.491,7.491,0,0,1,.215-2.025A2.167,2.167,0,0,1,20.75,3.2a2.625,2.625,0,0,1,1.26.284,2.1,2.1,0,0,1,.812.738,2.913,2.913,0,0,1,.392.937,7.475,7.475,0,0,1,.106,1.474V8.909a12.324,12.324,0,0,1-.1,1.839,2.915,2.915,0,0,1-.417,1.094,1.979,1.979,0,0,1-.815.749,2.6,2.6,0,0,1-1.149.241,3.19,3.19,0,0,1-1.224-.206A1.68,1.68,0,0,1,18.835,12a2.771,2.771,0,0,1-.4-1.009,9.727,9.727,0,0,1-.118-1.775V6.835Zm1.658,3.576a.845.845,0,1,0,1.682,0V5.619a.845.845,0,1,0-1.682,0Zm-5.858,2.42h2l0-6.9L18.48.012H16.3L15.041,4.405,13.769,0H11.607l2.51,5.93Z"
          transform="translate(-7.5)"
          fill="#272727"
        />
      </g>
      <g
        id="_003-twitter"
        data-name="003-twitter"
        transform="translate(178.265 0)"
      >
        <g id="Grupo_48" data-name="Grupo 48" transform="translate(0)">
          <path
            id="Caminho_5"
            data-name="Caminho 5"
            d="M40.336,51.88a17.24,17.24,0,0,1-4.765,1.306,8.223,8.223,0,0,0,3.638-4.571,16.526,16.526,0,0,1-5.244,2,8.269,8.269,0,0,0-14.3,5.655,8.515,8.515,0,0,0,.192,1.886,23.406,23.406,0,0,1-17.044-8.65A8.271,8.271,0,0,0,5.35,60.56a8.166,8.166,0,0,1-3.736-1.018v.091a8.307,8.307,0,0,0,6.625,8.125,8.253,8.253,0,0,1-2.168.272A7.311,7.311,0,0,1,4.5,67.888a8.348,8.348,0,0,0,7.727,5.76A16.615,16.615,0,0,1,1.979,77.175,15.49,15.49,0,0,1,0,77.062a23.28,23.28,0,0,0,12.686,3.711c15.217,0,23.536-12.6,23.536-23.531,0-.366-.013-.718-.03-1.069A16.5,16.5,0,0,0,40.336,51.88Z"
            transform="translate(0 -48)"
            fill="#272727"
          />
        </g>
      </g>
      <g
        id="_004-instagram"
        data-name="004-instagram"
        transform="translate(278.227 0)"
      >
        <g id="Grupo_50" data-name="Grupo 50">
          <g id="Grupo_49" data-name="Grupo 49">
            <path
              id="Caminho_6"
              data-name="Caminho 6"
              d="M23.253,0H9.52A9.531,9.531,0,0,0,0,9.52V23.253a9.531,9.531,0,0,0,9.52,9.52H23.253a9.531,9.531,0,0,0,9.52-9.52V9.52A9.531,9.531,0,0,0,23.253,0Zm6.96,23.253a6.967,6.967,0,0,1-6.96,6.96H9.52a6.967,6.967,0,0,1-6.96-6.96V9.52A6.967,6.967,0,0,1,9.52,2.56H23.253a6.967,6.967,0,0,1,6.96,6.96Z"
              fill="#272727"
            />
          </g>
        </g>
        <g
          id="Grupo_52"
          data-name="Grupo 52"
          transform="translate(7.553 7.553)"
        >
          <g id="Grupo_51" data-name="Grupo 51">
            <path
              id="Caminho_7"
              data-name="Caminho 7"
              d="M126.833,118a8.833,8.833,0,1,0,8.833,8.833A8.843,8.843,0,0,0,126.833,118Zm0,15.106a6.273,6.273,0,1,1,6.273-6.273A6.28,6.28,0,0,1,126.833,133.106Z"
              transform="translate(-118 -118)"
              fill="#272727"
            />
          </g>
        </g>
        <g
          id="Grupo_54"
          data-name="Grupo 54"
          transform="translate(24.445 6.664)"
        >
          <g id="Grupo_53" data-name="Grupo 53" transform="translate(0 0)">
            <ellipse
              id="Elipse_2"
              data-name="Elipse 2"
              cx="0.833"
              cy="0.833"
              rx="0.833"
              ry="0.833"
              fill="#272727"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SocialIcons;
