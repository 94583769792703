import { Context } from '@/context';
import { dataMenuLabelDesktop } from '@/data/menu.data';
import scssStyles from '@/utils/scssStyles';
import React, { FC, useContext } from 'react';
import styles from '../../navigation/navigation.module.scss';
import DataLayer from '@/utils/DataLayer';

const MenuLabelDesktop: FC = () => {
  const { dispatch, state } = useContext(Context);

  return (
    <>
      {dataMenuLabelDesktop().map((item, index) => {
        const active = item.reference
          ? item.reference.id === state.layout.currentlyVisibleNav ||
            item.id === state.layout.currentlyVisibleNav
          : item.id === state.layout.currentlyVisibleNav;
        const layerEvent = () => {
          DataLayer.clickEvent({
            elementCategory: 'link',
            element: item.label,
            pageSection: 'header',
            pageSubsection: 'menu',
          });

        };
        return (
          <a
            key={item.id}
            className={scssStyles([
              styles.menuLinks,
              active ? styles.active : '',
            ])}
            href={item.url}
            onAuxClick={() => layerEvent()}
            onClick={(e) => {
              layerEvent();
              if (index === 0) {
                window?.scrollTo({ top: 0, behavior: 'smooth' });
              } else {
                dispatch({
                  type: 'SCROLL_TO_REFERENCE',
                  payload: { reference: item },
                });
              }
              dispatch({
                type: 'SET_MENU_OPEN',
                payload: { menuOpen: false },
              });
              e.preventDefault();
            }}
          >
            {item.label}
          </a>
        );
      })}
    </>
  );
};

export default MenuLabelDesktop;
