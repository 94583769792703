import { includeResponsive } from "@/utils/imagePrefixes";

const PREFIX = `${process.env.BASE_PREFIX}images/`;
const SECTION_PREFIX = "ProductDetails/";

const DESIGN_PREFIX = `${SECTION_PREFIX}design/`;
export const design = {
  personalidade: includeResponsive(
    `${DESIGN_PREFIX}fiat-argo-personalidade`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  designInterno: includeResponsive(
    `${DESIGN_PREFIX}fiat-argo-acabamento-design-interno`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  conjuntoLuzes: includeResponsive(
    `${DESIGN_PREFIX}fiat-argo-conjunto-luzes`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  pinturaBicolor: includeResponsive(
    `${DESIGN_PREFIX}fiat-argo-pintura-bicolor`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const TECNOLOGIA_PREFIX = `${SECTION_PREFIX}tecnologia/`;
export const tecnologia = {
  centralMultimidia: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-argo-tecnologia-central-multimidia`,
    "webp",
    { prefix: PREFIX }
  ),
  volanteMulti: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-argo-tecnologia-volante-multifuncional`,
    "webp",
    { prefix: PREFIX }
  ),
  keyless: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-argo-tecnologia-keyless`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const PERFORMANCE_PREFIX = `${SECTION_PREFIX}performance/`;
export const performance = {
  motorFirefly10: includeResponsive(
    `${PERFORMANCE_PREFIX}fiat-argo-performance-motor-firefly-10`,
    "webp",
    { prefix: PREFIX }
  ),
  motorFirefly13: includeResponsive(
    `${PERFORMANCE_PREFIX}fiat-argo-performance-motor-firefly-13`,
    "webp",
    { prefix: PREFIX }
  ),
  direcaoEletrica: includeResponsive(
    `${PERFORMANCE_PREFIX}fiat-argo-performance-direcao-eletrica`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  cambioCVT: includeResponsive(
    `${PERFORMANCE_PREFIX}novo-cambio-automatico-cvt`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const SEGURANCA_PREFIX = `${SECTION_PREFIX}seguranca/`;
export const seguranca = {
  hillHolder: includeResponsive(
    `${SEGURANCA_PREFIX}fiat-argo-seguranca-hill-holder`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  controleTracao: includeResponsive(
    `${SEGURANCA_PREFIX}fiat-argo-seguranca-controle-tracao`,
    "webp",
    { prefix: PREFIX }
  ),
  cameraRe: includeResponsive(
    `${SEGURANCA_PREFIX}fiat-argo-seguranca-camera-re`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const SERVICOS_PREFIX = `${SECTION_PREFIX}servicos/`;
export const servicos = {
  cuidado: includeResponsive(`${SERVICOS_PREFIX}cuidado-com-seu-fiat`, "webp", {
    prefix: PREFIX,
  }),
  revisoes: includeResponsive(`${SERVICOS_PREFIX}revisao-sob-medida`, "webp", {
    prefix: PREFIX,
  }),
};

const ACESSORIOS_PREFIX = `${SECTION_PREFIX}acessorios/`;
export const acessorios = {
  frisosLaterais: includeResponsive(
    `${ACESSORIOS_PREFIX}fiat-argo-acessorios-friso`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  tapetePortaMalas: includeResponsive(
    `${ACESSORIOS_PREFIX}fiat-argo-acessorios-tapete`,
    "webp",
    { prefix: PREFIX }
  ),
  farolNeblina: includeResponsive(
    `${ACESSORIOS_PREFIX}fiat-argo-acessorios-farol-neblina`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  reboque: includeResponsive(
    `${ACESSORIOS_PREFIX}fiat-argo-acessorios-engate`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  adesivoTeto: includeResponsive(
    `${ACESSORIOS_PREFIX}fiat-argo-acessorios-adesivo-teto`,
    "webp",
    { prefix: PREFIX }
  ),
};
