import { FC } from "react";

const VersionsOrnament: FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="629.299"
      height="443.853"
      viewBox="0 0 629.299 443.853"
      className={className}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          y1="0.445"
          x2="0.26"
          y2="0.662"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff1430" />
          <stop offset="1" stopColor="#3b369e" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-0.049"
          y1="0.474"
          x2="0.923"
          y2="0.451"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ffb80f" />
          <stop offset="1" stopColor="#ff1430" />
        </linearGradient>
      </defs>
      <g id="bg-versao" transform="translate(-700.433 -1127.907)">
        <path
          id="União_22"
          data-name="União 22"
          d="M0,442.817,143.68,0H600.142l0,.012H628.1l-.013,442.8-171.62,0v0Z"
          transform="translate(701.121 1128.429)"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          fill="url(#linear-gradient)"
        />
        <path
          id="União_14"
          data-name="União 14"
          d="M-1,442.817,142.68,0,563.04-.022h.846l.027,442.841H563.9v.012Z"
          transform="translate(765.319 1128.429)"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          fill="url(#linear-gradient-2)"
        />
      </g>
    </svg>
  );
};

export default VersionsOrnament;
