import {
  CSSProperties,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react';
import getRandomInt from '@/utils/getRandomInt';
import scssStyles from '@/utils/scssStyles';
import styles from './SectionElement.module.scss';
import { MenuLabels } from '@/models';
import PulseStore from '@/store/PulseStore';
import { useMobxStores } from '@/store';
import { useIsActive } from '@/hooks';
import { PRODUCT_DETAILS_PREFIX } from '@/data/menu.data';
import { observer } from 'mobx-react-lite';
import { Context } from '@/context';

interface SectionElementProps {
  id?: string;
  className?: string;
  children?: ReactNode;
  navReference?: MenuLabels;
  overrideReference?: boolean;
  onSlugSuffix?: (suffix: string) => void;
  slugPrefixes?: string[];
  onVisibilityChange?: (isVisible: boolean) => void;
  getElement?: (element: HTMLDivElement) => void;
  style?: CSSProperties;
  handleKeyDown?: (e: KeyboardEvent, isVisible: boolean) => void;
  noPadding?: boolean;
}

const SectionElement: FC<SectionElementProps> = ({
  id,
  className = '',
  children,
  navReference,
  overrideReference = false,
  onSlugSuffix,
  slugPrefixes = [],
  onVisibilityChange,
  getElement,
  handleKeyDown,
  style = {},
  noPadding = false,
}) => {
  const ref = useRef<HTMLElement>(null);
  const isVisible = useIsActive(ref, 100);
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    if (!!onVisibilityChange) {
      onVisibilityChange(isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && navReference && !overrideReference) {
      dispatch({
        type: 'SET_CURRENTLY_VISIBLE_NAV',
        payload: {
          view: navReference,
        },
      });
    }
  }, [dispatch, isVisible, overrideReference]);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      handleKeyDown && handleKeyDown(e, isVisible);
    };
    if (handleKeyDown) {
      document?.body?.addEventListener('keydown', onKeydown);
    }

    return () => {
      document?.body?.removeEventListener('keydown', onKeydown);
    };
  }, [handleKeyDown, isVisible]);

  useEffect(() => {
    if (
      onSlugSuffix &&
      navReference &&
      !state.layout.slugSuffixes[navReference.slug] &&
      (slugPrefixes.length > 0
        ? !state.layout.slugSuffixes[slugPrefixes[0]]
        : true)
    ) {
      if (slugPrefixes.length > 0) {
        slugPrefixes.forEach((slug) => {
          dispatch({
            type: 'SET_SLUG_SUFFIXES',
            payload: {
              reference: { ...navReference, slug },
              func: onSlugSuffix,
            },
          });
        });
      } else {
        dispatch({
          type: 'SET_SLUG_SUFFIXES',
          payload: {
            reference: navReference,
            func: onSlugSuffix,
          },
        });
      }
    }
  }, [dispatch, navReference]);
  // dispatch, navReference, onSlugSuffix, slugPrefixes, state.layout.slugSuffixes

  useEffect(() => {
    if (getElement && ref.current) {
      getElement(ref.current as unknown as HTMLDivElement);
    }
  }, [ref, getElement]);

  useEffect(() => {
    if (navReference && ref) {

      dispatch({
        type: 'SET_NAV_REF',
        payload: {
          slug: navReference.slug,
          ref: ref,
        },
      });
    }
  }, [ref, dispatch]);

  return (
    <section
      id={id ?? `section-${getRandomInt()}`}
      ref={ref}
      className={scssStyles([
        styles.container,
        className,
        noPadding ? styles.noPadding : '',
      ])}
      style={style}
    >
      {children}
    </section>
  );
};

export default SectionElement;
