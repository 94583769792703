import "@styles/globals.scss";
import "swiper/css";
import dynamic from "next/dynamic";
import { getStore, StoreProvider } from "@store/index";
import { useEffect } from "react";
import Head from "next/head";
import NextApp, { AppContext, AppProps } from "next/app";
import { StickyCTA } from "@/components";
import { metaTags } from "@/constants";
import { ContextProvider } from "@/context";
import { CookieHolder } from "@/sections/cookie-holder";

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const RedeclaredAndHacky_Component = Component as any;
  const store = getStore();

  useEffect(() => {
    if (store && window?.location?.search) {
      const queryObject = window.location.search;
      if (queryObject) {
        store.setQueryString(new URLSearchParams(queryObject));
      }
    }
  }, [store]);

  return (
    <>
      <Head>
        <title>{metaTags.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <StoreProvider value={store}>
        <ContextProvider>
          <div style={{ position: "relative", zIndex: 0 }}>
            <RedeclaredAndHacky_Component {...pageProps} />
          </div>
          <StickyCTA />
          <CookieHolder />
        </ContextProvider>
      </StoreProvider>
    </>
  );
};

MyApp.getInitialProps = async (
  appContext: AppContext
): Promise<{
  pageProps: AppProps["pageProps"];
}> => {
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
};

export default dynamic(() => Promise.resolve(MyApp), {
  ssr: false,
});
