import React from 'react';

import { NextImageProps } from '@models/index';

import NextImage from 'next/image';

const Image = ({
  src,
  alt,
  title,
  className,
  loading = 'lazy',
  objectFit = 'contain',
}: NextImageProps): JSX.Element => {
  return (
    <NextImage
      src={String(src)}
      alt={alt}
      title={title}
      className={className}
      layout="fill"
      objectFit={objectFit}
      loading={loading}
    />
  );
};

export default Image;
