import { FC } from "react";

const HeroCardOrnament: FC<{ active?: boolean, className?:string }> = ({active=false, className}) => {
  const color = active? "#FB1533":"#FFB80F"
  return (
    <svg
      id="Grupo_4648"
      data-name="Grupo 4648"
      xmlns="http://www.w3.org/2000/svg"
      width="15.898"
      height="11.555"
      viewBox="0 0 15.898 11.555"
      className={className}
    >
      <path
        id="Caminho_2464"
        data-name="Caminho 2464"
        d="M14.813,0,11.064,11.556H12.8L16.55,0Z"
        transform="translate(-7.593 0)"
        fill={color}
      />
      <path
        id="Caminho_2465"
        data-name="Caminho 2465"
        d="M3.749,0,0,11.556H1.736L5.485,0Z"
        transform="translate(0 0)"
        fill={color}
      />
      <path
        id="Caminho_2466"
        data-name="Caminho 2466"
        d="M25.876,0,22.127,11.556h1.736L27.611,0Z"
        transform="translate(-15.185 0)"
        fill={color}
      />
      <path
        id="Caminho_2467"
        data-name="Caminho 2467"
        d="M36.941,0,33.191,11.556h1.736L38.676,0Z"
        transform="translate(-22.778 0)"
        fill={color}
      />
    </svg>
  );
};

export default HeroCardOrnament;
