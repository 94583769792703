import { dataVersions } from "@/data/versions.data";
import {
  IncludePrefix,
  includePrefix,
  IncludePrefixResponsiveSizes,
  includeResponsive,
  includeVideoResponsive,
  PREFIX,
} from "@/utils/imagePrefixes";
import * as Stories from "./components";

const brand = {
  logoHeader: `${PREFIX}brand/logoHeader.png`,
  logoProduct: `${PREFIX}brand/logoProduct.png`,
  logoProductDark: `${PREFIX}brand/logoProductDark.png`,
  logoHeaderDesktop: `${PREFIX}brand/logoHeaderDesktop.svg`,
  whatsBtn: `${PREFIX}brand/whatsapp.svg`,
  fiatSimbol: `${PREFIX}brand/shortFiatIcon.svg`,
};

const ui = {
  menuClosed: `${PREFIX}ui/menuClosed.svg`,
  pointer: `${PREFIX}ui/pointer.png`,
  accordionChevron: `${PREFIX}ui/accordionChevron.svg`,
  ibama: `${PREFIX}ui/ibama.svg`,
  arrowNext: `${PREFIX}ui/arrowNext.svg`,
  arrowPrev: `${PREFIX}ui/arrowPrev.svg`,
  touch: `${PREFIX}ui/touch.svg`,

  instagram: `${PREFIX}ui/instagramIcon.svg`,
  facebook: `${PREFIX}ui/facebookIcon.svg`,
  twitter: `${PREFIX}ui/twitterIcon.svg`,
  youtube: `${PREFIX}ui/youtubeIcon.svg`,
};

const stamps = {
  stamp1: `${PREFIX}stamps/stamp-1.png`,
  stamp2: `${PREFIX}stamps/stamp-2.png`,
  stamp3: `${PREFIX}stamps/stamp-3.png`,
  stamp4: `${PREFIX}stamps/stamp-4.png`,
};

const HERO_PREFIX = "HeroDesktop/desktop/";
const STORIES_PREFIX = "Stories/";
const INTERNAL_360_PATH = `${PREFIX}internal360/desktop/`;
const INTERNAL_360_PATH_MOBILE = `${PREFIX}internal360/mobile/`;

const heroContent = {
  firstContent: `${PREFIX}HeroDesktop/firstContent.svg`,
};

const THUMB_PREFIX = "thumbs/";
const BACKGROUND_PREFIX = "backgrounds/";

const heroImages = {
  mainBg: includeResponsive(`${HERO_PREFIX}backgrounds/fiat-argo-2024`, "webp"),
  loremI: includeResponsive(
    `${HERO_PREFIX}backgrounds/fiat-argo-cambio-automatico-cvt`,
    "webp"
  ),
  loremII: includeResponsive(
    `${HERO_PREFIX}backgrounds/fiat-argo-conectividade`,
    "webp"
  ),
  loremIII: includeResponsive(
    `${HERO_PREFIX}backgrounds/fiat-argo-keyless`,
    "webp"
  ),
  loremIV: includeResponsive(
    `${HERO_PREFIX}backgrounds/fiat-argo-pneu-uso-misto`,
    "webp"
  ),
  loremV: includeResponsive(
    `${HERO_PREFIX}backgrounds/fiat-argo-controle-tracao`,
    "webp"
  ),
  loremVI: includeResponsive(
    `${HERO_PREFIX}backgrounds/fiat-argo-ar-condicionado-digital`,
    "webp"
  ),
  heroThumb01: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-cambio-automatico-cvt-thumb`,
    "webp"
  ),
  heroThumb02: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-conectividade-thumb`,
    "webp"
  ),
  heroThumb03: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-keyless-thumb`,
    "webp"
  ),
  heroThumb04: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-pneu-uso-misto-thumb`,
    "webp"
  ),
  heroThumb05: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-controle-tracao-thumb`,
    "webp"
  ),
  heroThumb06: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-ar-condicionado-digital-thumb`,
    "webp"
  ),
};
const soriesImages = {
  mainBg: includeResponsive(`${STORIES_PREFIX}fiat-argo-2024`, "webp"),
  loremI: includeResponsive(
    `${STORIES_PREFIX}fiat-argo-cambio-automatico-cvt`,
    "webp"
  ),
  loremII: includeResponsive(
    `${STORIES_PREFIX}fiat-argo-conectividade`,
    "webp"
  ),
  loremIII: includeResponsive(`${STORIES_PREFIX}fiat-argo-keyless`, "webp"),
  loremIV: includeResponsive(
    `${STORIES_PREFIX}fiat-argo-pneu-uso-misto`,
    "webp"
  ),
  loremV: includeResponsive(
    `${STORIES_PREFIX}fiat-argo-controle-tracao`,
    "webp"
  ),
  loremVI: includeResponsive(
    `${STORIES_PREFIX}fiat-argo-ar-condicionado-digital`,
    "webp"
  ),
  heroThumb01: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-cambio-automatico-cvt-thumb`,
    "webp"
  ),
  heroThumb02: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-conectividade-thumb`,
    "webp"
  ),
  heroThumb03: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-keyless-thumb`,
    "webp"
  ),
  heroThumb04: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-pneu-uso-misto-thumb`,
    "webp"
  ),
  heroThumb05: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-controle-tracao-thumb`,
    "webp"
  ),
  heroThumb06: includeResponsive(
    `${HERO_PREFIX}${THUMB_PREFIX}fiat-argo-ar-condicionado-digital-thumb`,
    "webp"
  ),
};

const bannerEnduranceImages = {
  banner: "/images/Banner-endurance/argo-endurance.webp",
  banner2x: "/images/Banner-endurance/argo-endurance@2x.webp",
  banner3x: "/images/Banner-endurance/argo-endurance@3x.webp",

  bannerMobile: "/images/Banner-endurance/argo-endurance.webp",
  bannerMobile2x: "/images/Banner-endurance/argo-endurance@2x.webp",
  bannerMobile3x: "/images/Banner-endurance/argo-endurance@3x.webp",
};
const bannerImages = {
  banner: "/images/Banner/novo-motor-firefly.webp",
  banner2x: "/images/Banner/novo-motor-firefly@2x.webp",
  banner3x: "/images/Banner/novo-motor-firefly@3x.webp",

  bannerMobile: "/images/Banner/novo-motor-firefly.webp",
  bannerMobile2x: "/images/Banner/novo-motor-firefly@2x.webp",
  bannerMobile3x: "/images/Banner/novo-motor-firefly@3x.webp",
};

export const heroBackgrounds = {
  loremI: includeResponsive(
    `HeroDesktop/mobile/backgrounds/fiat-argo-2024`,
    "webp"
  ),
  loremII: includeResponsive(
    `HeroDesktop/mobile/backgrounds/fiat-argo-cambio-automatico-cvt`,
    "webp"
  ),
  loremIII: includeResponsive(
    `HeroDesktop/mobile/backgrounds/fiat-argo-conectividade`,
    "webp"
  ),
  loremIV: includeResponsive(
    `HeroDesktop/mobile/backgrounds/fiat-argo-keyless`,
    "webp"
  ),
  loremV: includeResponsive(
    `HeroDesktop/mobile/backgrounds/fiat-argo-pneu-uso-misto`,
    "webp"
  ),
  loremVI: includeResponsive(
    `HeroDesktop/mobile/backgrounds/fiat-argo-controle-tracao`,
    "webp"
  ),
  loremVII: includeResponsive(
    `HeroDesktop/mobile/backgrounds/fiat-argo-ar-condicionado-digital`,
    "webp"
  ),
};

const ReserveCTA = {
  backgroundImage: includeResponsive(
    "ReserveCTA/fiat-pulse-suv-reserve",
    "jpg"
  ),
};

const dealImages ={
  backgroundImage: `${PREFIX}/Deal/deal-background.svg`
}
interface ReducedVersions {
  [x: string]: IncludePrefixResponsiveSizes;
}
export interface ReducedVersionsThumbs {
  [x: string]: IncludePrefix;
}

const versions: ReducedVersions = dataVersions.reduce(
  (p, c) => ({
    ...p,
    [c.id]: includeResponsive(`Versions/${c.id}`, "webp"),
  }),
  {}
);

const versionsThumbs: ReducedVersionsThumbs = dataVersions.reduce(
  (p, c) => ({
    ...p,
    [c.id]: includePrefix(`Versions/thumb/${c.id}-thumb`, "webp"),
  }),
  {}
);

const versionsFeatures: ReducedVersionsThumbs = dataVersions.reduce((p, c) => {
  const featuresImg = c.details.reduce(
    (detailsAcc, detail) => ({
      ...detailsAcc,
      [detail[1]]: includeResponsive(`Versions/features/${detail[1]}`, "jpg"),
    }),
    {}
  );
  return {
    ...p,
    [c.id]: featuresImg,
  };
}, {});

const components = {
  Stories,
};

export * as productDetails from "./productDetails";

export {
  bannerImages,
  brand,
  ui,
  components,
  heroContent,
  heroImages,
  ReserveCTA,
  bannerEnduranceImages,
  versions,
  stamps,
  versionsThumbs,
  versionsFeatures,
  INTERNAL_360_PATH,
  INTERNAL_360_PATH_MOBILE,
  soriesImages,
  dealImages
};
