import { productDetails as productDetailsAssets } from "@/assets";
import { MenuLabels } from "@/models";
import { IncludePrefixResponsiveSizes } from "@/utils/imagePrefixes";
import { PRODUCT_DETAILS_REF } from "./menu.data";

export interface ProductTabSlide {
  id: string;
  index?: number;
  title: JSX.Element;
  titleSEO: string;
  slug: string;
  descriptionDesktop: JSX.Element;
  descriptionMobile: JSX.Element;
  parentSlug?: string;
  image: IncludePrefixResponsiveSizes;
  vimeoId?: {
    mobile: string;
    desktop: string;
  };
  tabId: string;
}

export interface ProductTab {
  id: string;
  title: string;
  slug: string;
  parentSlug?: string;
  children: Omit<ProductTabSlide, "tabId">[];
}

const productDetails: ProductTab[] = [
  {
    id: "design",
    title: "Design",
    slug: "design",
    children: [
      {
        id: "personalidade-argo",
        title: <>PERSONALIDADE <br />ARGO</>,
        titleSEO: "Personalidade Argi",
        slug: "personalidade-argo",
        descriptionDesktop: (
          <p>
            Design esportivo e exclusivo até nos pequenos detalhes. O Fiat Argo
            tem a essência italiana, a robustez brasileira e um estilo único no
            segmento.
          </p>
        ),
        descriptionMobile: (
          <p>
            Design esportivo e exclusivo até nos pequenos detalhes. O Fiat Argo
            tem a essência italiana, a robustez brasileira e um estilo único no
            segmento.
          </p>
        ),
        image: productDetailsAssets.design.personalidade,
      },
      {
        id: "acabamento-design-interno",
        title: <>ACABAMENTO <br />E DESIGN INTERNO</>,
        titleSEO: "Acabamento e design interno",
        slug: "acabamento-design-interno",
        descriptionDesktop: (
          <p>
            A flag italiana e o novo logo Fiat também aparecem no interior do
            carro, que possui acabamento impecável e detalhes escurecidos.
          </p>
        ),
        descriptionMobile: (
          <p>
            A flag italiana e o novo logo Fiat também aparecem no interior do
            carro, que possui acabamento impecável e detalhes escurecidos.
          </p>
        ),
        image: productDetailsAssets.design.designInterno,
      },
      {
        id: "conjunto-de-luzes",
        title: <>CONJUNTO <br />DE LUZES</>,
        titleSEO:"Conjunto de luzes",
        slug: "conjunto-de-luzes",
        descriptionDesktop: (
          <p>
            Desenvolvido com parábolas duplas, ou seja, duas lâmpadas, uma para
            produzir a luz baixa e outra para produzir a luz alta, os faróis
            emitem um facho de luz amplo, garantindo uma viagem mais segura.​
          </p>
        ),
        descriptionMobile: (
          <p>
            Desenvolvido com parábolas duplas, ou seja, duas lâmpadas, uma para
            produzir a luz baixa e outra para produzir a luz alta, os faróis
            emitem um facho de luz amplo, garantindo uma viagem mais segura.​{" "}
          </p>
        ),
        image: productDetailsAssets.design.conjuntoLuzes,
      },
      {
        id: "pintura-bicolor",
        title: <>PINTURA <br />BICOLOR</>,
        slug: "pintura-bicolor",
        titleSEO: "Pintura bicolor",
        descriptionDesktop: (
          <p>
            O único da categoria com personalização do teto, retrovisores e
            aerofólio. É exclusividade que não acaba.
          </p>
        ),
        descriptionMobile: (
          <p>
            O único da categoria com personalização do teto, retrovisores e
            aerofólio. É exclusividade que não acaba.
          </p>
        ),
        image: productDetailsAssets.design.pinturaBicolor,
      },
    ],
  },
  {
    id: "tecnologia",
    title: "Tecnologia",
    slug: "tecnologia",
    children: [
      {
        id: "central-multimidia",
        title: <>CENTRAL <br /> Multimídia 7”</>,
        titleSEO: "Central multimídia",
        slug: "central-multimidia",
        descriptionDesktop: (
          <p>
            Conexão via Android Auto e Apple CarPlay para você dirigir sempre
            conectado com o que mais importa.{" "}
          </p>
        ),
        descriptionMobile: (
          <p>
            Conexão via Android Auto e Apple CarPlay para você dirigir sempre
            conectado com o que mais importa.
          </p>
        ),
        image: productDetailsAssets.tecnologia.centralMultimidia,
      },
      {
        id: "volante-multifuncional",
        title: <>VOLANTE<br /> MULTIFUNCIONAL</>,
        titleSEO: "Volante multifuncional",
        slug: "volante-multifuncional",
        descriptionDesktop: (
          <p>
            No Fiat Argo, você controla as funções de rádio e atende chamadas de
            telefone sem tirar as mãos do volante. É o controle total na palma
            das suas mãos.
          </p>
        ),
        descriptionMobile: (
          <p>
            No Fiat Argo, você controla as funções de rádio e atende chamadas de
            telefone sem tirar as mãos do volante. É o controle total na palma
            das suas mãos.
          </p>
        ),
        image: productDetailsAssets.tecnologia.volanteMulti,
      },
      {
        id: "keyless-entry-and-go",
        title: <>KEYLESS<br /> ENTRY N’GO</>,
        titleSEO: "Keyless entry n'go",
        slug: "keyless-entry-and-go",
        descriptionDesktop: (
          <p>
            A tecnologia Keyless permite que você abra as portas e ligue o motor
            do Fiat Argo sem precisar tocar na chave. A ligação com seu Fiat Argo nunca
            foi tão forte!​
          </p>
        ),
        descriptionMobile: (
          <p>
            A tecnologia Keyless permite que você abra as portas e ligue o motor
            do Fiat Argo sem precisar tocar na chave. A ligação com seu Fiat Argo nunca
            foi tão forte!​´
          </p>
        ),
        image: productDetailsAssets.tecnologia.keyless,
      },
    ],
  },
  // TAB 03 - PERFORMANCE
  {
    id: "performance",
    title: "Performance",
    slug: "performance",
    children: [
      {
        id: "motor-firefly-10",
        title: <>MOTOR <br /> FIREFLY 1.0</>,
        titleSEO: "Motor firefly 1.0",
        slug: "motor-firefly-10",
        descriptionDesktop: (
          <p>
            O motor Firefly é extremamente econômico e ainda possui respostas
            rápidas, com o melhor torque da categoria, para uma direção ágil na
            cidade.
          </p>
        ),
        descriptionMobile: (
          <p>
            O motor Firefly é extremamente econômico e ainda possui respostas
            rápidas, com o melhor torque da categoria, para uma direção ágil na
            cidade.
          </p>
        ),
        image: productDetailsAssets.performance.motorFirefly10,
      },
      {
        id: "motor-firefly-13",
        title: <>MOTOR <br /> FIREFLY 1.3</>,
        titleSEO: "Motor firefly 1.3",
        slug: "motor-firefly-13",
        descriptionDesktop: (
          <p>
            O Fiat Argo tem a essência italiana, a robustez brasileira e um
            estilo único no segmento. Equipado com o motor Firefly 1.3 você irá
            experimentar o que há de melhor na relação entre performance e
            economia.
          </p>
        ),
        descriptionMobile: (
          <p>
            O Fiat Argo tem a essência italiana, a robustez brasileira e um
            estilo único no segmento. Equipado com o motor Firefly 1.3 você irá
            experimentar o que há de melhor na relação entre performance e
            economia.
          </p>
        ),
        image: productDetailsAssets.performance.motorFirefly13,
      },
      {
        id: "direcao-eletrica-progressiva",
        title: <>Direção Elétrica <br /> Progressiva</>,
        titleSEO: "Direção elétrica progressiva",
        slug: "direcao-eletrica-progressiva",
        descriptionDesktop: (
          <p>
            O conforto e a economia das direções elétricas com mais segurança ao
            ajustar o nível de esforço do volante em altas velocidades.{" "}
          </p>
        ),
        descriptionMobile: (
          <p>
            O conforto e a economia das direções elétricas com mais segurança ao
            ajustar o nível de esforço do volante em altas velocidades.{" "}
          </p>
        ),
        image: productDetailsAssets.performance.direcaoEletrica,
      },
    ],
  },
  // TAB 04 - SEGURANCA
  {
    id: "seguranca",
    title: "Segurança",
    slug: "seguranca",
    children: [
      {
        id: "controle-de-tracao-e-estabilidade",
        title: <>Controle de Tração <br />e Estabilidade</>,
        titleSEO: "Controle de tração e estabilidade",
        slug: "controle-de-tracao-e-estabilidade",
        descriptionDesktop: (
          <p>
            Para ajudar a manter o controle da direção em condições extremas, a
            tração e a estabilidade do Fiat Argo são controladas
            automaticamente.
          </p>
        ),
        descriptionMobile: (
          <p>
            Para ajudar a manter o controle da direção em condições extremas, a
            tração e a estabilidade do Fiat Argo são controladas
            automaticamente.{" "}
          </p>
        ),
        image: productDetailsAssets.seguranca.controleTracao,
      },
      {
        id: "hill-holder",
        title: <>HILL <br /> HOLDER</>,
        titleSEO: "Hill holders",
        slug: "hill-holder",
        descriptionDesktop: (
          <p>
            Chega de passar nervoso! Com o auxiliar de partida em ladeiras, o
            carro evita dar marcha ré, segurando o freio automaticamente, mesmo
            nos terrenos mais acentuados.​{" "}
          </p>
        ),
        descriptionMobile: (
          <p>
            Chega de passar nervoso! Com o auxiliar de partida em ladeiras, o
            carro evita dar marcha ré, segurando o freio automaticamente, mesmo
            nos terrenos mais acentuados.​{" "}
          </p>
        ),
        image: productDetailsAssets.seguranca.hillHolder,
      },
      {
        id: "camera-de-re",
        title: <>CÂMERA <br />DE RÉ</>,
        titleSEO: "Câmera de ré",
        slug: "camera-de-re",
        descriptionDesktop: (
          <p>
            Ao engatar a marcha ré, a câmera na parte traseira é acionada,
            mostrando os obstáculos pelo caminho, direto na Central Multimídia.
          </p>
        ),
        descriptionMobile: (
          <p>
            Ao engatar a marcha ré, a câmera na parte traseira é acionada,
            mostrando os obstáculos pelo caminho, direto na Central Multimídia.
          </p>
        ),
        image: productDetailsAssets.seguranca.cameraRe,
      },
    ],
  },
  // TAB 05 - ACESSORIOS
  {
    id: "acessorios",
    title: "Acessórios",
    slug: "acessorios",
    children: [
      {
        id: "friso-lateral-pintado",
        title: <>FRISO LATERAL <br/> PINTADO</>,
        titleSEO: "Friso lateral pintado",
        slug: "friso-lateral-pintado",
        descriptionDesktop: (
          <p>
            Cuidado redobrado com a lateral do seu Fiat Argo. Os frisos laterais
            protegem as portas de pequenos danos e ainda trazem mais estilo para
            o carro.​
          </p>
        ),
        descriptionMobile: (
          <p>
            Cuidado redobrado com a lateral do seu Fiat Argo. Os frisos laterais
            protegem as portas de pequenos danos e ainda trazem mais estilo para
            o carro.​e estão na rua, guiando você do jeito mais seguro no seu
            trajeto.
          </p>
        ),
        image: productDetailsAssets.acessorios.frisosLaterais,
      },
      {
        id: "tapete-do-porta-malas",
        title: <>TAPETE DO<br />PORTA-MALAS</>,
        titleSEO: "Tapete do porta malas",
        slug: "tapete-do-porta-malas",
        descriptionDesktop: (
          <p>
            Protege o forro do porta-malas e ainda garante mais estabilidade e
            aderência aos itens colocados no bagageiro.
          </p>
        ),
        descriptionMobile: (
          <p>
            Protege o forro do porta-malas e ainda garante mais estabilidade e
            aderência aos itens colocados no bagageiro.{" "}
          </p>
        ),
        image: productDetailsAssets.acessorios.tapetePortaMalas,
      },
      {
        id: "farol-de-neblina",
        title: <>FARÓL DE <br />NEBLINA</>,
        titleSEO: "Farol de neblina",
        slug: "farol-de-neblina",
        descriptionDesktop: (
          <p>
            Melhoram sua visibilidade em casos de neblina e garantem mais
            destaque para o seu Fiat Argo por onde você passar.​
          </p>
        ),
        descriptionMobile: (
          <p>
            Melhoram sua visibilidade em casos de neblina e garantem mais
            destaque para o seu Fiat Argo por onde você passar.​{" "}
          </p>
        ),
        image: productDetailsAssets.acessorios.farolNeblina,
      },
      {
        id: "engate-reboque",
        title: <>ENGATE <br/>REBOQUE</>,
        titleSEO: "Engate reboque",
        slug: "engate-reboque",
        descriptionDesktop: (
          <p>
            Desenvolvido exclusivamente para o Fiat Argo, o engate reboque tem
            acoplamento prático e forte, além do acabamento resistente contra a
            corrosão e ferrugem.
          </p>
        ),
        descriptionMobile: (
          <p>
            Desenvolvido exclusivamente para o Fiat Argo, o engate reboque tem
            acoplamento prático e forte, além do acabamento resistente contra a
            corrosão e ferrugem.
          </p>
        ),
        image: productDetailsAssets.acessorios.reboque,
      },
      {
        id: "adesivo-plotado-no-teto",
        title: <>ADESIVO <br />PLOTADO NO TETO</>,
        titleSEO: "Adesivo plotado no teto",
        slug: "adesivo-plotado-no-teto",
        descriptionDesktop: (
          <p>
            Que tal um design ainda mais esportivo? O envelopamento do teto é o
            acessório ideal para se destacar dos demais.
          </p>
        ),
        descriptionMobile: (
          <p>
            Que tal um design ainda mais esportivo? O envelopamento do teto é o
            acessório ideal para se destacar dos demais.{" "}
          </p>
        ),
        image: productDetailsAssets.acessorios.adesivoTeto,
      },
    ],
  },
  // TAB 06 - SERVICOS
  {
    id: "servicos",
    title: "Serviços",
    slug: "servicos",
    children: [
      {
        id: "o-cuidado-que-seu-fiat-merece",
        title: <>O Cuidado que o seu <br />Fiat Argo merece</>,
        titleSEO: "Cuidado que o seu Fiat Argo merece",
        slug: "o-cuidado-que-seu-fiat-argo-merece",
        descriptionDesktop: (
          <p>
            Conheça os pacotes de Serviços Fiat FlexCare. O seu Fiat Argo pode ter
            até 5 anos de garantia além das Revisões sob medida com descontos e
            preços fixos.{" "}
          </p>
        ),
        descriptionMobile: (
          <p>
            Conheça os pacotes de Serviços Fiat FlexCare. O seu Fiat Argo pode ter
            até 5 anos de garantia além das Revisões sob medida com descontos e
            preços fixos.{" "}
          </p>
        ),
        image: productDetailsAssets.servicos.cuidado,
      },
      {
        id: "revisao-sob-medida",
        title: <>Revisões <br/>Sob Medida</>,
        titleSEO: "Revisões sob medida",
        slug: "revisao-sob-medida",
        descriptionDesktop: (
          <p>
            Mais economia para você e a Fiat Argo sempre bem cuidada. Monte um
            plano de revisões com descontos e preços fixos.
          </p>
        ),
        descriptionMobile: (
          <p>
            Mais economia para você e a Fiat Argo sempre bem cuidada. Monte um
            plano de revisões com descontos e preços fixos.
          </p>
        ),
        image: productDetailsAssets.servicos.revisoes,
      },
    ],
  },
];

export const getProductDetails = (): MenuLabels[] => {
  if (!productDetails) {
    return [];
  }
  return productDetails.reduce((prev, item, index) => {
    return [
      ...prev,
      {
        numberId: 0,
        id: `data-${item.id}`,
        label: item.title,
        slug: item.slug,
        url: `/${item.slug}`,
        prefixRelation: PRODUCT_DETAILS_REF,
        isDetails: true
      },
    ];
  }, [] as MenuLabels[]);
};

export const getProductDetailsv2 = (pDetails: ProductTab[],): MenuLabels[] => {
  return pDetails.reduce<MenuLabels[]>((prev, item) => {
      return [
          ...prev,
          {
              numberId: 0,
              id: `data-${item.id}`,
              label: item.title,
              slug: item.slug,
              url: `/${item.slug}`,
              prefixRelation: PRODUCT_DETAILS_REF,
              isDetails: true
          }
      ];
  }, []);
};

let index = -1;

export const allSlides: ProductTabSlide[] = productDetails.reduce(
  (prev, curr) => {
    return [
      ...prev,
      ...curr.children.map((c) => {
        index++;
        return {
          ...c,
          index,
          parentSlug: curr.slug,
          tabId: curr.id,
        };
      }),
    ];
  },
  [] as ProductTabSlide[]
);

export default productDetails;
