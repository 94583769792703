import { Context } from "@/context";
import { CardsItemsProps } from "@/models";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import styles from "./Cards.module.scss";
import DataLayer from "@/utils/DataLayer";

export interface CardsProps {
  items: CardsItemsProps[];
}

const Cards: FC<CardsProps> = observer(({ items }) => {
  const { state, dispatch } = useContext(Context);

  const handleSetMainDisplayIndex = (index: number) => {
    dispatch({
      type: "SET_MAIN_DISPLAY_INDEX",
      payload: { mainDisplayIndex: index },
    });
  };

  const isFirstCard = state.layout.mainDisplayIndex === 0;

  return (
    <div className={styles.container}>
      <div className={styles.cards}>
        {items.map((item, index) => {
          return (
            <button
              className={[
                index === state.layout.mainDisplayIndex - 1
                  ? styles.selected
                  : "",
                isFirstCard ? styles.firstCard : "",
              ].join(" ")}
              onClick={() => {
                handleSetMainDisplayIndex(index + 1);
                DataLayer.clickEvent({
                  elementCategory: "card",
                  element: item.title.toString(),
                  pageSection: "conteudo",
                  pageSubsection: "fiat-argo",
                });
              }}
              key={`card-${index}`}
            >
              {item.title}
            </button>
          );
        })}
      </div>

      <a
        href="#"
        className={[styles.anchor, isFirstCard ? styles.firstCard : ""].join(
          " "
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.634"
          height="11.443"
          viewBox="0 0 18.634 11.443"
        >
          <path
            id="seta"
            d="M0,0,8.326,8.256,16.513,0"
            transform="translate(1.056 1.065)"
            fill="none"
            stroke="#fc1430"
            strokeWidth="3"
          />
        </svg>

        <span>Arraste para saber mais</span>
      </a>
    </div>
  );
});

export default Cards;
